import React from 'react'
import PropTypes from 'prop-types'
import styles from './LoadMore.module.scss'
import Spinner from '@/component/Primitive/Spinner'

const LoadMore = ({ text, loadMoreMethod, visible, busy, onLoadMore }) => {
  if (!visible) {
    return null
  }

  if (loadMoreMethod === 'button') {
    return (
      <button
        className={styles.LoadMoreButton}
        disabled={busy}
        onClick={onLoadMore}
      >
        {busy ? <Spinner visible /> : text}
      </button>
    )
  }

  return null
}

LoadMore.propTypes = {
  text: PropTypes.string.isRequired,
  loadMoreMethod: PropTypes.string,
  visible: PropTypes.bool,
  busy: PropTypes.bool,
  onLoadMore: PropTypes.func
}

export default LoadMore
