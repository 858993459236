import React, { useEffect, useState } from 'react'
import styles from './SearchFilter.module.scss'
import classNames from 'classnames'
import Icon from '@/component/Primitive/Icon'
import DownArrow from '@/asset/img/redesign24/down-arrow.svg'
import { array, bool, string } from 'prop-types'
import { useRouter } from 'next/router'
import Spinner from '../Spinner'

const ratingOptions = ['U', 'PG', '12A', '12', '15', '18']
// const genreOptions = [
//   'action',
//   'adventure',
//   'animation',
//   'children',
//   'comedy',
//   'documentary',
//   'drama, horror',
//   'science fiction',
//   'western',
//   'crime',
//   'thriller',
//   'musical',
//   'romance'
// ].sort() //!TBC
const typeOptions = [
  'All',
  'Film',
  'TV Show',
  'Trailer',
  'Case Studies',
  'Articles',
  'Podcasts',
  'News'
]
const SearchFilter = ({
  checkedRatingFilters,
  checkedTypeFilters,
  checkedGenreFilters,
  shorterTypesList,
  searchTerm,
  hideAdultContentOption,
  includeR18
}) => {
  const typeOptionsList = shorterTypesList
    ? typeOptions.slice(1, 4)
    : typeOptions
  const router = useRouter()
  const [ratingValues, setRatingValues] = useState(checkedRatingFilters || [])
  // const [genreValues, setGenreValues] = useState(checkedGenreFilters || []) //!TBC
  const [typeValues, setTypeValues] = useState(
    checkedTypeFilters && checkedTypeFilters.length > 0
      ? checkedTypeFilters
      : typeOptionsList
  )
  const [toggleGenreList, setToggleGenreList] = useState(false)
  const [toggleTypeList, setToggleTypeList] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [adultContent, setAdultContent] = useState(includeR18)

  // This is so we can apply a submitting state to the button
  // when the route changes based on the filters
  useEffect(() => {
    const startHandler = () => {
      setSubmitting(true)
    }
    const completeHandler = () => {
      setSubmitting(false)
    }

    router.events.on('routeChangeStart', startHandler)
    router.events.on('routeChangeComplete', completeHandler)
    router.events.on('routeChangeError', completeHandler)
    return () => {
      router.events.off('routeChangeStart', startHandler)
      router.events.off('routeChangeComplete', completeHandler)
      router.events.off('routeChangeError', completeHandler)
    }
  }, [router.events])

  const handleSelectRatingValues = (e) => {
    if (ratingValues.includes(e.target.value)) {
      setRatingValues(ratingValues.filter((item) => item !== e.target.value))
    } else {
      setRatingValues([...ratingValues, e.target.value])
    }
  }

  // const handleSelectGenre = (e) => {
  //   if (genreValues.includes(e.target.value)) {
  //     setGenreValues(genreValues.filter((item) => item !== e.target.value))
  //   } else {
  //     setGenreValues([...genreValues, e.target.value])
  //   }
  // } //!TBC

  const handleSelectType = (e) => {
    if (e.target.value === 'All') {
      setTypeValues(typeValues.includes('All') ? [] : typeOptions)

      return
    }
    if (typeValues.includes(e.target.value)) {
      setTypeValues(typeValues.filter((item) => item !== e.target.value))
    } else {
      setTypeValues([...typeValues, e.target.value])
    }
  }

  const submitForm = () => {
    const params = new URLSearchParams()

    params.set('q', searchTerm || '')

    // if (genreValues.length !== 0) {
    //   genreValues.forEach((genre) => {
    //     params.append('g[]', genre)
    //   })
    // } //!TBC
    if (typeValues.length !== 0) {
      const restOfSiteFilters = [
        'Case studies',
        'Podcasts',
        'News',
        'Resources'
      ]
      typeValues.forEach((type) => {
        if (restOfSiteFilters.find((item) => item === type)) {
          params.append('c[]', type)
        } else {
          params.append('t[]', type)
        }
      })
    }
    if (ratingValues.length !== 0) {
      params.set('s', 'Relevance')
      ratingValues.forEach((rating) => {
        params.append('cl[]', rating)
      })
    }

    if (adultContent) {
      params.append('r18', 'Show R18 Content')
    } else {
      params.delete('r18')
    }

    const searchUrl = `/search?${params.toString()}`

    router.push(searchUrl)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      setToggleGenreList(false)
      setToggleTypeList(false)
    }
  }

  return (
    <>
      <div className={styles.MainContent}>
        <div className={classNames(styles.RatingWrap)}>
          {ratingOptions.map((option, index) => {
            const customisedIcon = index === 0 || index === 1
            return (
              <div key={option} className={classNames(styles.RatingItemWrap)}>
                <div
                  className={classNames(
                    styles.RatingItemIconWrap,
                    customisedIcon && styles.RatingItemIconWrapCustomised
                  )}
                >
                  <input
                    type="checkbox"
                    value={option}
                    className={styles.CheckboxInput}
                    checked={ratingValues.includes(option)}
                    onChange={(e) => handleSelectRatingValues(e)}
                  />
                  <Icon
                    className={styles.RatingItemIcon}
                    type={`rating-${option.toLowerCase()}`}
                    a11yText={`rating-${option.toLowerCase()}`}
                  />
                </div>

                <div className={styles.CheckboxWrap}>
                  <input
                    type="checkbox"
                    value={option}
                    className={styles.CheckboxInput}
                    checked={ratingValues.includes(option)}
                    onChange={(e) => handleSelectRatingValues(e)}
                  />
                  <span className={styles.RatingItemCheckBox} />
                </div>
              </div>
            )
          })}
        </div>
        <div>
          <div className={styles.RightMainContent}>
            <div className={classNames(styles.SelectWrap)}>
              <button
                className={classNames(
                  styles.ToggleButton,
                  toggleTypeList && styles.ActiveToggleButton
                )}
                onClick={() => {
                  setToggleGenreList(false)
                  setToggleTypeList(!toggleTypeList)
                }}
              >
                {typeValues.length === 0 ? (
                  'Select Types'
                ) : (
                  <span>{typeValues.map((o) => o).join(', ')}</span>
                )}
                <img src={DownArrow} alt="" />
              </button>

              <div
                className={classNames(
                  styles.ToggleContent,
                  toggleTypeList && styles.ActiveToggleContent
                )}
              >
                {typeOptionsList.map((option) => (
                  <div
                    key={option}
                    className={classNames(styles.ContentOptionWrap)}
                  >
                    <div className={styles.ContentOption}>
                      {option}
                      <input
                        type="checkbox"
                        value={option}
                        className={styles.CheckboxInput}
                        checked={typeValues.includes(option)}
                        onChange={(e) => handleSelectType(e)}
                      />
                      <div className={styles.CheckboxWrap}>
                        <input
                          type="checkbox"
                          value={option}
                          className={styles.CheckboxInput}
                          checked={typeValues.includes(option)}
                          onChange={(e) => handleSelectType(e)}
                        />
                        <span className={styles.RatingItemCheckBox} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* Hide it for now to wait for client's decision */}
            {/* <div className={classNames(styles.SelectWrap)}>
              <button
                className={classNames(
                  styles.ToggleButton,
                  toggleGenreList && styles.ActiveToggleButton
                )}
                onClick={() => {
                  setToggleTypeList(false)
                  setToggleGenreList(!toggleGenreList)
                }}
              >
                {genreValues.length === 0 ? (
                  'Select Genre'
                ) : (
                  <span>{genreValues.map((o) => o).join(', ')}</span>
                )}{' '}
                <img src={DownArrow} alt="" />
              </button>

              <div
                className={classNames(
                  styles.ToggleContent,
                  toggleGenreList && styles.ActiveToggleContent
                )}
              >
                {genreOptions.map((option) => (
                  <div
                    key={option}
                    className={classNames(styles.ContentOptionWrap)}
                  >
                    <div className={styles.ContentOption}>
                      {option}
                      <input
                        type="checkbox"
                        value={option}
                        className={styles.CheckboxInput}
                        checked={genreValues.includes(option)}
                        onChange={(e) => handleSelectGenre(e)}
                      />
                      <div className={styles.CheckboxWrap}>
                        <input
                          type="checkbox"
                          value={option}
                          className={styles.CheckboxInput}
                          checked={genreValues.includes(option)}
                          onChange={(e) => handleSelectGenre(e)}
                        />
                        <span className={styles.RatingItemCheckBox} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}

            <button
              onClick={submitForm}
              className={classNames('button--teal', styles.SelectWrap)}
              disabled={submitting}
            >
              {submitting ? <Spinner /> : 'Apply'}
            </button>
          </div>
          {!hideAdultContentOption && (
            <div className={styles.AdultContentWrap}>
              <div className={styles.CheckboxWrap}>
                <input
                  type="checkbox"
                  value={adultContent}
                  className={styles.CheckboxInput}
                  checked={adultContent}
                  onChange={(e) => setAdultContent(e.target.checked)}
                />
                <span className={styles.RatingItemCheckBox} />
              </div>
              <span>
                Show adult content (this may include explicit and/or offensive
                titles)
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role="button"
        className={classNames(
          styles.Overlay,
          (toggleGenreList || toggleTypeList) && styles.ActiveOverlay
        )}
        onClick={() => {
          setToggleGenreList(false)
          setToggleTypeList(false)
        }}
      />
    </>
  )
}
SearchFilter.propTypes = {
  checkedRatingFilters: array,
  checkedTypeFilters: array,
  checkedGenreFilters: array,
  shorterTypesList: bool,
  includeR18: bool,
  searchTerm: string,
  hideAdultContentOption: bool
}
export default SearchFilter
